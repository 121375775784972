.header-right
    .title
        color: #FFF
        text-align: right
        font-family: Tungsten
        font-size: 1.56vw
        font-style: normal
        font-weight: 350
        line-height: 100%
    .subtitle
        color: #FFF
        text-align: right
        font-family: Arial
        font-size: 0.73vw
        font-style: normal
        font-weight: 700
        line-height: 110%
