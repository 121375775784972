.header-center
    .title
        color: #DF9926
        text-align: center
        font-family: Tungsten
        font-size: 2.08vw
        font-style: normal
        font-weight: 350
        line-height: 71.754%
        letter-spacing: 1.2px
        text-transform: uppercase
    .subtitle
        color: #FFF
        text-align: center
        font-family: Arial
        font-size: 0.83vw
        font-style: normal
        font-weight: 400
        line-height: 152%
        letter-spacing: 5.12px
        text-transform: uppercase
