.home-page
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    .logo
        margin-top: 11.57vh
    .title
        margin-top: 3.66vh
        color: #DF9926
        text-align: center
        font-family: Tungsten
        font-size: 6.19106rem
        font-style: normal
        font-weight: 350
        line-height: 95.672%
        letter-spacing: 0.18575rem
        text-transform: uppercase
    .subtitle
        margin-top: 1.06vh
        color: #FFF
        text-align: center
        font-family: Arial
        font-size: 2.52681rem
        font-style: normal
        font-weight: 400
        line-height: 152%
        letter-spacing: 0.80856rem
        text-transform: uppercase
    .box
        filter: drop-shadow(0px 0px 30px #DF9926)
        margin-top: 4.75vh
        position: relative
        width: 14.69vw
        max-width: 282px
        img
            border-radius: 0.625rem
            width: 100%
        .btn
            position: absolute
            bottom: 0px
            border-radius: 0.3125rem
            border: 1px solid #016FD0
            background: #FFF
            display: flex
            padding: 0.8125rem 1.25rem
            justify-content: center
            align-items: center
            gap: 0.3125rem
            color: #016FD0
            text-align: center
            font-family: Arial
            font-size: 1.25rem
            font-style: normal
            font-weight: 400
            line-height: 100%
            -webkit-user-select: none
            user-select: none
            left: 50%
            transform: translate(-50%, -38%)
            width: 75%
            cursor: pointer
